import React from 'react'

const Cart = () => {
  return (
    <div>
      Cart
    </div>
  )
}

export default Cart
